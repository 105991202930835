module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z1YEJPV4MN"],"gtagConfig":{}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"YUHEI ONO","short_name":"YUHEI ONO","start_url":"/","background_color":"#f3eee7","theme_color":"#313131","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"def7d1990d98e41ad8dd9ff2a4bbf62c"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
